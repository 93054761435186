import { SectionSeparatorText } from '@shared-components/sectionSeparatorText'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Layout } from '../../styles/layout'
import { About } from './components/about/about'
import { Contact } from './components/contact/contact'
import { FixedContactBar } from './components/fixedContactBar/fixedContactBar'
import { Home } from './components/home/home'
import { LoadingScreen } from './components/loadingScreen/loadingScreen'
import { Navbar } from './components/navbar/navbar'
import { TechStack } from './components/techStack/techStack'
import { WorkSection } from './components/work/work'

export const Main = () => {
  const aboutSectionRef = useRef<HTMLDivElement>(null)
  const techSectionRef = useRef<HTMLDivElement>(null)
  const workSectionRef = useRef<HTMLDivElement>(null)
  const contactSectionRef = useRef<HTMLDivElement>(null)
  const [loadingVisible, setLoading] = useState(true)

  const [showNavbarBG, setShowNavbarBG] = useState(false)

  const [hideContactBar, setHideContactBar] = useState(false)

  const scrollToSection = useCallback(
    (section: 'about' | 'tech' | 'work' | 'contact') => {
      console.log(section)
      if (section === 'about') {
        aboutSectionRef.current?.scrollIntoView({ behavior: 'smooth' })
      } else if (section === 'tech') {
        techSectionRef.current?.scrollIntoView({ behavior: 'smooth' })
      } else if (section === 'work') {
        workSectionRef.current?.scrollIntoView({ behavior: 'smooth' })
      } else if (section === 'contact') {
        contactSectionRef.current?.scrollIntoView({ behavior: 'smooth' })
      }
    },
    []
  )

  const onReadMoreClick = React.useCallback(() => scrollToSection('about'), [])

  const unmountLoadingOverlay = () => {
    setLoading(false)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Layout>
      {loadingVisible && <LoadingScreen unmountMe={unmountLoadingOverlay} />}
      <Wrapper>
        <Navbar
          visible={!loadingVisible}
          showBackground={showNavbarBG}
          {...{ scrollToSection }}
        />
        <FixedContactBar visible={!hideContactBar && !loadingVisible} />
        <Home
          {...{ setShowNavbarBG, showLoading: loadingVisible, onReadMoreClick }}
        />
        <About ref={aboutSectionRef} />
        <SectionSeparatorText
          text={'Design  \u2022  Develop  \u2022  Deploy'}
        />
        <TechStack ref={techSectionRef} />
        <SectionSeparatorText
          text={'\u201c Better than I expected \u201d'}
          subtext={'-everyone'}
        />
        <WorkSection ref={workSectionRef} />
        <Contact ref={contactSectionRef} hideContactBar={setHideContactBar} />
      </Wrapper>
    </Layout>
  )
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  /* background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 85vh,
    rgba(0, 0, 0, 0) 155vh
  ); */
  /* background-image: radial-gradient(
    ellipse at 50vw 0vh,
    rgba(0, 0, 0, 1) 90vh,
    rgba(0, 0, 0, 0) 150vh
  ); */
`
