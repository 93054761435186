import {
  CTAButton,
  SectionBodyText,
  SectionHeader,
  SectionSubheader,
} from '@styles/styles'
import React, { useState } from 'react'
import { useInView } from 'react-intersection-observer'
import styled from 'styled-components'
import { work_data } from './data'
import { AppStoreButton } from './_appstoreButton'

export const WorkMobile = () => {
  return (
    <Wrapper>
      <CardsContainer>
        {work_data.map((data) => (
          <Item key={data.header} data={data} />
        ))}
      </CardsContainer>
    </Wrapper>
  )
}

interface ItemProps {
  data: typeof work_data[number]
}

const Item = ({ data }: ItemProps) => {
  const [viewRef, inView] = useInView({ threshold: 0.2, triggerOnce: true })

  const [showDescription, setShowDescription] = useState(false)

  const onPress = () => setShowDescription((s) => !s)

  /**Required to prevent bubbling to the mobile card causing the description to also activate */
  const stopPropagation = (e: React.MouseEvent<HTMLElement>) =>
    e.stopPropagation()

  return (
    <CardWrapper
      ref={viewRef}
      color={data.app_color}
      onClick={onPress}
      visible={inView}
    >
      <SummaryContainer minimize={showDescription}>
        <IconContainer>
          <Icon src={data.logo} />
        </IconContainer>
        <SummaryHeaderContainer>
          <HeaderText>{data.header}</HeaderText>
          <SubheaderText>{data.subheader}</SubheaderText>
        </SummaryHeaderContainer>
        <ScreenshotContainer
          image={data.imageMobile}
          // visible={inView}
        ></ScreenshotContainer>
        {/* <SubheaderText>{data.section.subheader}</SubheaderText> */}
        <LinkButtonsWrapper>
          {data.website && (
            <CTAButton
              href={data.website}
              target='_blank'
              rel='noopener noreferrer'
              noMarginTop
              onClick={stopPropagation}
            >
              Visit Utilify
            </CTAButton>
          )}
          {data.appStore && (
            <AppStoreButton
              store={'ios'}
              link={data.appStore}
              disabled={data.appStore == 'disabled'}
              marginRight
            />
          )}
          {data.playStore && (
            <AppStoreButton
              store={'android'}
              link={data.playStore}
              disabled={data.playStore == 'disabled'}
            />
          )}
        </LinkButtonsWrapper>
      </SummaryContainer>
      <DescriptionContainer visible={showDescription}>
        <DescriptionHeader visible={showDescription}>
          {data.header}
        </DescriptionHeader>
        <DescriptionSubheader visible={showDescription}>
          {data.platformText}
        </DescriptionSubheader>
        <DescriptionText visible={showDescription}>{data.body}</DescriptionText>
      </DescriptionContainer>
    </CardWrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  min-width: 100%;
  /* background-color: teal; */
  margin: 10vh 0;
`

const CardsContainer = styled.div`
  display: flex;
  min-width: 100%;
  flex-direction: column;
  align-items: center;
`

interface ItemWrapperProps {
  color: string
  visible: boolean
}

const card_width = '80vw'

const CardWrapper = styled.a<ItemWrapperProps>`
  position: relative;
  box-sizing: border-box;
  width: ${card_width};
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  background-color: ${(props) => props.color};
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.9) 70%);
  padding: 2em 2em;
  border-radius: 1em;
  box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%),
    0 1px 10px 0 rgb(0 0 0 / 12%);
  transition: 500ms;
  transform: translateY(${(props) => (props.visible ? 0 : 20)}px);
  opacity: ${(props) => (props.visible ? 1 : 0)};
`

interface SummaryContainerProps {
  minimize: boolean
}

const SummaryContainer = styled.div<SummaryContainerProps>`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  transition: 500ms;
  width: 100%;
  transform: scale(${(props) => (props.minimize ? 0.9 : 1)});
`

interface ScreenshotContainerProps {
  image: string
  // visible: boolean
}

const ScreenshotContainer = styled.div<ScreenshotContainerProps>`
  width: 100%;
  height: 40vw;
  padding: 1em 0;
  background-image: url(${(props) => props.image});
  background-size: 100%;
  background-repeat: no-repeat;
  transition: 500ms;
  background-position: 50% 50%;
`

const IconContainer = styled.div`
  width: 30%;
  /* height: 100%; */
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  text-align: center;
`

const Icon = styled.img`
  width: 50%;
  height: auto;
  object-fit: contain;
`

const SummaryHeaderContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

const HeaderText = styled.h1`
  font-family: ${(props) => props.theme.fontFamily.regular};
  font-weight: 500;
  font-size: ${(props) => props.theme.mobile.fontSize.body};
  color: ${(props) => props.theme.colors.text1};
  text-align: center;
`

const SubheaderText = styled.h2`
  font-family: ${(props) => props.theme.fontFamily.regular};
  font-weight: 400;
  font-size: ${(props) => props.theme.mobile.fontSize.small};
  color: ${(props) => props.theme.colors.text2};
  text-align: center;
`

const LinkButtonsWrapper = styled.div`
  display: flex;
`

interface DescriptionProps {
  visible: boolean
}

const description_transition = 500

const DescriptionContainer = styled.div<DescriptionProps>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  padding: 2em 2em;
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
  background-color: rgba(0, 0, 0, 0.9);
  /* background-image: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.6)); */
  /* backdrop-filter: saturate(173%) blur(6px); */
  transition: ${description_transition}ms;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transform: translateY(${(props) => (props.visible ? 0 : 100)}%);
  overflow-y: auto;
`

const children_transition = 250
const transition_delay = 100

const DescriptionHeader = styled(SectionHeader)<DescriptionProps>`
  font-size: ${(props) => props.theme.mobile.fontSize.header};
  text-align: center;
  transition: ${children_transition}ms;
  transition-delay: ${(props) =>
    props.visible ? description_transition / 2 + 1 * transition_delay : 0}ms;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transform: translateY(${(props) => (props.visible ? 0 : 10)}%);
`

const DescriptionSubheader = styled(SectionSubheader)<DescriptionProps>`
  font-size: ${(props) => props.theme.mobile.fontSize.body};
  text-align: center;
  transition: ${children_transition}ms;
  transition-delay: ${(props) =>
    props.visible ? description_transition / 2 + 2 * transition_delay : 0}ms;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transform: translateY(${(props) => (props.visible ? 0 : 10)}%);
`

const DescriptionText = styled(SectionBodyText)<DescriptionProps>`
  font-size: ${(props) => props.theme.mobile.fontSize.small};
  text-align: center;
  transition: ${children_transition}ms;
  transition-delay: ${(props) =>
    props.visible ? description_transition / 2 + 3 * transition_delay : 0}ms;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transform: translateY(${(props) => (props.visible ? 0 : 10)}%);
`
