import { MOBILE_BREAK_POINT } from '@styles/styles'
import React from 'react'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'
import { WorkDesktop } from './components/workDesktop/workDesktop'
import { WorkMobile } from './components/workMobile'

interface IsProps {}

export const WorkSection = React.forwardRef(
  ({}: IsProps, scrollRef: React.Ref<HTMLDivElement>) => {
    const isMobile = useMediaQuery({
      query: `(max-width: ${MOBILE_BREAK_POINT}px)`,
    })

    if (isMobile) {
      return <WorkMobile />
    } else {
      return (
        <Wrapper ref={scrollRef}>
          <WorkDesktop />
        </Wrapper>
      )
    }
  }
)

const Wrapper = styled.div``
