import about_bg from '@images/about_bg.png'
import { SectionMainText } from '@shared-components/sectionMainText'
import {
  MOBILE_BREAK_POINT,
  MOBILE_SECTION_PADDING,
  WIDTHS,
} from '@styles/styles'
import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'
import { aboutData } from './aboutData'
import { AboutAnimation } from './_aboutAnimation'

interface IsProps {}

export const About = React.forwardRef(
  ({}: IsProps, scrollRef: React.Ref<HTMLDivElement>) => {
    const [viewRef, inView] = useInView({ threshold: 0.8 })

    const isMobile = useMediaQuery({
      query: `(max-width: ${MOBILE_BREAK_POINT}px)`,
    })

    const [showDescription, setShowDescription] = useState(false)

    useEffect(() => {
      //So that the description only animates once.
      if (inView) setShowDescription(true)
    }, [inView])

    return (
      <Wrapper ref={scrollRef}>
        {/* <BackgroundImage src={about_bg} /> */}
        <BackgroundImgContainer />
        {!isMobile && <AboutAnimation {...{ inView }} />}
        <Container ref={viewRef}>
          <SectionMainText
            visible={showDescription}
            wrapperStyles={isMobile ? undefined : styleSheet.sectionTextWrapper}
            {...aboutData}
          />
        </Container>
      </Wrapper>
    )
  }
)

const styleSheet = {
  sectionTextWrapper: {
    maxWidth: '45%',
  },
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    flex-direction: column;
    min-height: auto;
    margin: ${MOBILE_SECTION_PADDING} 0;
  }
  /* overflow-x: hidden;
  overflow-y: visible;
  scroll&::-webkit-scrollbar {
    display: none;
  } */
`

const BackgroundImgContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 150vh;
  background-image: url(${about_bg});
  background-position: 147% 26%;
  background-size: 84%;
  background-repeat: no-repeat;
  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    background-size: 262%;
    background-position: 35% 51%;
  }
`

const Container = styled.div`
  position: relative;
  min-height: 100%;
  width: 100%;
  max-width: ${(props) => props.theme.widths.maxDesktop};
  display: flex;
  flex-direction: column;
  /* background-color: teal; */
  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    align-items: center;
    justify-content: center;
    max-width: 100%;
    overflow: hidden;
  }
`

const BodyContainer = styled.div`
  width: 45%;
  /* max-width: ${(props) => props.theme.widths.maxDesktop}; */
  display: flex;
  flex: 1;
  flex-direction: column;
  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    width: 100%;
    max-width: ${WIDTHS.maxMobile};
  }
`
