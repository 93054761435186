import { SectionMainTextParams } from '@shared-components/sectionMainText'

export const techData: SectionMainTextParams = {
  header: 'Tech Stack',
  subheader: 'Full Stack Developer',
  body: [
    [
      {
        text: `A Javascript developer that works on both the front-end and back-end for mobile and web applications, with a heavier focus on the front-end aspects. I always aim to develop apps that feel both natural and intuitive to the user.`,
      },
    ],
    [
      {
        text: `Utilizing popular and reliable technology such as React, Redux, Firebase, AWS and more!`,
      },
    ],
    [
      {
        text: `Constantly learning, I'm always on the lookout for how we can optimize and improve performance on apps as technology improves.`,
      },
    ],
  ],
}
