import aws_icon from '@images/techStack/AWS.svg'
import expo_icon from '@images/techStack/Expo.svg'
import firebase_icon from '@images/techStack/Firebase.svg'
import gCloud_icon from '@images/techStack/GCloud.svg'
import js_icon from '@images/techStack/Javascript.svg'
import react_icon from '@images/techStack/React.svg'
import redux_icon from '@images/techStack/Redux.svg'
import sql_icon from '@images/techStack/SQL.svg'
import ts_icon from '@images/techStack/Typescript.svg'
import { MOBILE_BREAK_POINT } from '@styles/styles'
import React from 'react'
import { useInView } from 'react-intersection-observer'
import styled from 'styled-components'

const data = [
  { title: 'Typescript', image: ts_icon, color: '#3a5dce' },
  { title: 'Expo', image: expo_icon, color: '#ffffff' },
  { title: 'Javascript', image: js_icon, color: '#e0e700' },
  { title: 'Redux', image: redux_icon, color: '#8800bd' },
  { title: 'React', image: react_icon, color: '#5bccf0' },
  { title: 'SQL', image: sql_icon, color: '#4987dd' },
  { title: 'Firebase', image: firebase_icon, color: '#f88400' },
  { title: 'G Cloud', image: gCloud_icon, color: '#6653f6' },
  { title: 'AWS', image: aws_icon, color: '#ffa000' },
]

export const TechStackCarousel = () => {
  //Don't use the inView from techStack otherwise this will get triggered on mobile prematurely.
  const { ref, inView } = useInView({ threshold: 0.5 })

  return (
    <Wrapper {...{ ref }}>
      {data.map((card, i) => (
        <TechCard key={card.title} i={i} visible={inView} color={card.color}>
          <ImageContainer>
            <CardImage src={card.image} />
          </ImageContainer>
        </TechCard>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(3, 1fr);
  height: 100%;
  max-width: 40%;
  margin-left: 3rem;
  align-items: center;
  box-sizing: border-box;
  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    max-width: 100%;
    margin-left: 0;
    margin-top: 4em;
  }

  /* padding-left: 20px; */
  /* height: 100px; */
  /* width: calc(100vw / 2); */
  /* background-color: lightsalmon; */
  /* overflow-x: hidden; */
`

interface CardProps {
  color?: string
  i: number
  visible: boolean
}

const t_duration = 450
const t_delay = 80

export const techStackCarouselDuration = t_duration + t_delay * data.length - 1

const TechCard = styled.div<CardProps>`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  border-color: ${(props) => props.color};
  border-width: 2px;
  border-style: solid;
  border-radius: 1%;
  padding: 1em;
  transition: ${(props) => (props.visible ? t_duration : 250)}ms
    cubic-bezier(0.34, 1.56, 0.64, 1);
  transition-delay: ${(props) => (props.visible ? props.i * t_delay : 0)}ms;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transform: translateY(${(props) => (props.visible ? 0 : 10)}px);
`

const ImageContainer = styled.div`
  height: 80%;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const CardImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
`
