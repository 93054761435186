import { SectionMainTextParams } from '@shared-components/sectionMainText'

export const aboutData: SectionMainTextParams = {
  header: 'About',
  subheader: 'Developer / Explorer',
  body: [
    [
      {
        text: `Creating fluid experiences across web and mobile applications to effectively establish your business, brand, or products online identity. Harnessing the power of`,
      },
      {
        text: ` React `,
        link: 'https://reactjs.org/',
      },
      {
        text: `to create cross-platform solutions.`,
      },
    ],
    [
      {
        text: `When I'm not developing, I enjoy exploring different countries and cultures. Having been to over 60 countries so far, I love learning about how we all operate around the world. Finding for
          how technology can help improve our everyday lives.`,
      },
    ],
    [
      {
        text: `I've been working remotely for over 4 years and am always interested in hearing about new products or ideas! Feel free to`,
      },
      { text: ` get in touch `, link: '//TODO PUT A LINK HERE' },
      { text: `if you'd like to find out more!` },
    ],
  ],
}
