import { BACKGROUND_COLORS, COLORS, MOBILE_BREAK_POINT } from '@styles/styles'
import React from 'react'
import styled from 'styled-components'

interface IsProps {
  buttonText: string
  hoverText?: string
  hoverImage?: string
  onPress?: () => void
  href?: string
  altColor?: boolean
  smaller?: boolean
  noMargin?: boolean
  noBackground?: boolean
}

export const AnimatedCTAButton = ({
  buttonText,
  hoverText,
  hoverImage,
  onPress,
  href,
  altColor,
  smaller,
  noMargin,
  noBackground,
}: IsProps) => {
  return (
    <Wrapper
      {...{ altColor, smaller, noMargin, href, noBackground }}
      target={href ? '_blank' : undefined}
      rel={href ? 'noopener noreferrer' : undefined}
      onClick={onPress}
    >
      <Container>{buttonText}</Container>
      <SecondContainer>
        {hoverImage ? (
          <ButtonImage src={hoverImage} />
        ) : (
          hoverText || buttonText
        )}
      </SecondContainer>
    </Wrapper>
  )
}

interface ButtonProps {
  noMargin?: boolean
  noBackground?: boolean
  altColor?: boolean
  smaller?: boolean
}

const tDuration = 300
// const easing = 'cubic-bezier(0.34, 1.36, 0.64, 1)'
const easing = 'cubic-bezier(0.25, 1, 0.5, 1)'

export const Wrapper = styled.a<ButtonProps>`
  position: relative;
  display: inline-block;
  border-radius: 0.2em;
  border: ${(props) =>
    `1px solid ${props.altColor ? COLORS.secondary : COLORS.primary}`};
  font-family: ${(props) => props.theme.fontFamily.regular};
  font-weight: ${(props) => (props.smaller ? 600 : 200)};
  font-size: ${(props) =>
    props.smaller ? props.theme.fontSize.small : props.theme.fontSize.body};
  color: ${(props) => (props.altColor ? COLORS.text1 : COLORS.primary)};
  margin: ${(props) => (props.noMargin ? 0 : 20)}px 0px;
  cursor: pointer;
  transition: ${tDuration}ms;
  text-decoration: none;
  overflow: hidden;
  background-color: ${(props) =>
    props.noBackground ? 'transparent' : BACKGROUND_COLORS.screen};
  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    font-size: ${(props) => props.theme.mobile.fontSize.body};
    font-weight: 500;
  }
  &:hover {
    background-color: ${(props) =>
      `${props.altColor ? COLORS.secondary : COLORS.primary}1A`};

    /* color: ${(props) => props.theme.colors.text1}; */
  }
`

const Container = styled.div`
  display: flex;
  flex: 1;
  padding: 0.6em 1.7em;
  /* background-color: teal; */
  justify-content: center;
  align-items: center;
  transition: ${tDuration}ms ${easing};
  ${Wrapper}:hover & {
    transform: translate3d(0, -100%, 0);
  }

  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    padding: 0.5em 1.2em;
  }
`

const SecondContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: ${tDuration}ms ${easing};
  transform: translate3d(0, 100%, 0);
  ${Wrapper}:hover & {
    transform: translate3d(0, 0%, 0);
  }
`

const ButtonImage = styled.img`
  width: 1.4em;
  height: auto;
  object-fit: contain;
`

const ButtonText = styled.p`
  font-family: ${(props) => props.theme.fontFamily.regular};
  font-weight: 200;
  font-size: ${(props) => props.theme.fontSize.body};
  color: ${(props) => props.theme.colors.primary};
`
