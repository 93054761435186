import android_icon from '@images/android_white.svg'
import ios_icon from '@images/apple.svg'
import web_icon from '@images/chrome_white.svg'
import externalLinkIcon from '@images/externalLink.svg'
import { animated, useSpring } from '@react-spring/web'
import { AnimatedCTAButton } from '@shared-components/animatedCTAButton'
import { PADDINGS, SectionBodyText, WIDTHS } from '@styles/styles'
import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'
import { WorkData } from '../data'
import { AppStoreButton } from '../_appstoreButton'

type PlatformsTypes = 'ios' | 'android' | 'web'

const platforms: { icon: string; title: string; type: PlatformsTypes }[] = [
  {
    icon: android_icon,
    title: 'Android',
    type: 'android',
  },
  {
    icon: ios_icon,
    title: 'IOS',
    type: 'ios',
  },
  {
    icon: web_icon,
    title: 'Web',
    type: 'web',
  },
]

interface IsProps {
  data: WorkData
  active: boolean
  /**The "previous" item, if it becomes active will slide in from left */
  previous: boolean
  /**The "next" item, if it becomes active will slide in from right */
  next: boolean
  setIsAnimating: (animating: boolean) => void
}

const scaleDown = 0.98

export const WorkCard = ({
  data,
  active,
  previous,
  next,
  setIsAnimating,
}: IsProps) => {
  const [wrapperAnim, wrapperAnimAPI] = useSpring(() => ({
    // ref: reactLogoAnim,
    // config: { mass: 2, tension: 280, friction: 60 },
    config: { mass: 1, tension: 190, friction: 30 },
    // from: { opacity: 0, rotate: 0 },
    opacity: active ? 1 : 0,
    x: active ? '0' : previous ? '-100%' : '100%',
    scale: active ? 1 : scaleDown,
    // pause: true, //See above onRest call
    // delay: 750,
    // onRest: () => {
    //   setIsAnimating()
    // },
  }))

  useEffect(() => {
    if (active) {
      wrapperAnimAPI.start({
        to: [
          {
            opacity: 1,
            x: '0%',
          },
          {
            scale: 1,
          },
        ],
        onRest: () => setIsAnimating(false),
      })
    }
  }, [active])

  useEffect(() => {
    if (previous) {
      wrapperAnimAPI.start({
        to: [{ scale: scaleDown, opacity: 0, x: '-100%' }],
      })
    }
  }, [previous])

  useEffect(() => {
    if (next) {
      wrapperAnimAPI.start({
        to: [{ opacity: 0, x: '100%', scale: scaleDown }],
      })
    }
  }, [next])

  return (
    <Wrapper {...{ active }} style={wrapperAnim}>
      <CardWrapper color={data.app_color}>
        <LeftContainer>
          <ScreenshotImage {...{ active }} src={data.image} />
        </LeftContainer>
        <RightContainer>
          <AppLogoImage {...{ active }} src={data.logo} />
          <HeaderText
            order_i={1}
            {...{ active }}
            fontFamily={data.header_fontFamily}
            fontWeight={data.header_fontWeight}
            color={data.header_color}
          >
            {data.header}
          </HeaderText>
          <SubheaderText
            {...{ active }}
            order_i={2}
            fontFamily={data.subheader_fontFamily}
          >
            {data.subheader}
          </SubheaderText>
          <PlatformsWrapper>
            {platforms.map((platform) => (
              <PlatformItem {...{ active }} order_i={3} key={platform.title}>
                <PlatformImage
                  active={data.platforms[platform.type]}
                  src={platform.icon}
                />
                <PlatformText active={data.platforms[platform.type]}>
                  {platform.title}
                </PlatformText>
              </PlatformItem>
            ))}
          </PlatformsWrapper>
          <BodyContainer order_i={4} {...{ active }}>
            <BodyText>{data.body}</BodyText>
          </BodyContainer>
          <LinkButtonsWrapper order_i={5} {...{ active }}>
            {data.website && (
              <AnimatedCTAButton
                buttonText='Visit Utilify'
                hoverImage={externalLinkIcon}
                href={data.website}
                noBackground
              />
            )}
            {data.appStore && (
              <AppStoreButton
                store={'ios'}
                link={data.appStore}
                disabled={data.appStore == 'disabled'}
                marginRight
              />
            )}
            {data.playStore && (
              <AppStoreButton
                store={'android'}
                link={data.playStore}
                disabled={data.playStore == 'disabled'}
              />
            )}
          </LinkButtonsWrapper>
        </RightContainer>
      </CardWrapper>
    </Wrapper>
  )
}

interface ActiveProps {
  active: boolean
  order_i?: number
}

const t_duration = 600
const t_initial_delay = 2000
const t_delay = 100
const easing = 'cubic-bezier(0.61, 1, 0.88, 1)'

interface AnimStyleProps extends ActiveProps {}

const animStyles = css<AnimStyleProps>`
  /* transition: ${t_duration}ms ${easing};
  transition-delay: ${(props) =>
    t_initial_delay + (props.order_i || 0) * t_delay}ms;
  transform: translateY(${(props) => (props.active ? 0 : 20)}px);
  opacity: ${(props) => (props.active ? 1 : 0)}; */
`

interface WrapperProps {
  active: boolean
}

const Wrapper = styled(animated.div)<WrapperProps>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  /* max-width: ${WIDTHS.maxDesktop}; */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${(props) => (props.active ? 2 : 1)};
  /* background-color: teal; */
`

interface ColorProps {
  color?: string
}

export const workCardWidth = `calc(100% - ${PADDINGS.extendedContent * 4}px)`

const CardWrapper = styled.div<ColorProps>`
  display: flex;
  height: 85%;
  width: ${workCardWidth};
  background-color: #201f22;
  border-radius: 4vh;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0 1em;
  /* border: 1.5px solid ${(props) => props.color}; */
  box-shadow: 0px 1em 1.5em #000000;
`

const LeftContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: flex-end;
`

const RightContainer = styled.div`
  display: flex;
  flex: 1;
  max-height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 1.5em 0;
`

const ScreenshotImage = styled.img<ActiveProps>`
  height: auto
  width: auto;
  /* max-width: 10% */
  max-height: 84%;
  object-fit: contain;
  /* transition: ${t_duration}ms ${easing};
  transition-delay: ${t_initial_delay}ms;
  opacity: ${(props) => (props.active ? 1 : 0)};
  transform: translate3d(0, ${(props) => (props.active ? 0 : 80)}%, 0); */
  ${animStyles};
`

const AppLogoImage = styled.img<ActiveProps>`
  height: 9vh;
  width: auto;
  ${animStyles};
  /* transition: ${t_duration}ms ${easing};
  transition-delay: ${t_initial_delay}ms;
  transform: translateY(${(props) => (props.active ? 0 : 20)}px);
  opacity: ${(props) => (props.active ? 1 : 0)}; */
`

interface TextProps extends ActiveProps {
  fontFamily?: string
  fontWeight?: number
  color?: string
}

const HeaderText = styled.h1<TextProps>`
  font-family: ${(props) => props.fontFamily ?? props.theme.fontFamily.regular};
  font-weight: ${(props) => props.fontWeight ?? 500};
  font-size: 4.5vh;
  letter-spacing: ${(props) =>
    props.fontFamily == 'orbitron' ? '0.5vh' : undefined};
  color: ${(props) => props.color ?? props.theme.colors.text1};
  margin-top: 0.3vh;
  ${animStyles};
  /* transition: ${t_duration}ms ${easing};
  transition-delay: ${t_initial_delay}ms;
  transform: translateY(${(props) => (props.active ? 0 : 20)}px);
  opacity: ${(props) => (props.active ? 1 : 0)}; */
`

const SubheaderText = styled.h1<TextProps>`
  font-family: ${(props) => props.fontFamily ?? props.theme.fontFamily.regular};
  font-weight: 300;
  font-size: 2.8vh;
  color{(props) => props.theme.colors.text2};
  ${animStyles};
  /* transition: ${t_duration}ms ${easing};
  transition-delay: ${t_initial_delay}ms;
  transform: translateY(${(props) => (props.active ? 0 : 20)}px);
  opacity: ${(props) => (props.active ? 1 : 0)}; */
`

const PlatformsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 65%;
  padding: 1.8em 0;
`

const PlatformItem = styled.div<ActiveProps>`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${animStyles};
  /* transition: ${t_duration}ms ${easing};
  transition-delay: ${t_initial_delay}ms;
  transform: translateY(${(props) => (props.active ? 0 : 20)}px);
  opacity: ${(props) => (props.active ? 1 : 0)}; */
`

interface PlatformProps {
  active?: boolean
}

const PlatformImage = styled.img<PlatformProps>`
  height: 7vh;
  width: auto;
  opacity: ${(props) => (props.active ? 1 : 0.3)};
`

const PlatformText = styled.h1<PlatformProps>`
  font-family: ${(props) => props.theme.fontFamily.regular};
  font-weight: 200;
  font-size: 1.2em;
  color: ${(props) => props.theme.colors.text1};
  margin-top: 0.8em;
  opacity: ${(props) => (props.active ? 1 : 0.3)};
`

const BodyContainer = styled.div<ActiveProps>`
  max-width: 65%;
  ${animStyles};
  /* transition: ${t_duration}ms ${easing};
  transition-delay: ${t_initial_delay}ms;
  transform: translateY(${(props) => (props.active ? 0 : 20)}px);
  opacity: ${(props) => (props.active ? 1 : 0)}; */
`

const BodyText = styled(SectionBodyText)`
  text-align: center;
  font-weight: 200;
`

const LinkButtonsWrapper = styled.div<ActiveProps>`
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
  ${animStyles};
  /* transition: ${t_duration}ms ${easing};
  transition-delay: ${t_initial_delay}ms;
  transform: translateY(${(props) => (props.active ? 0 : 20)}px);
  opacity: ${(props) => (props.active ? 1 : 0)}; */
`
