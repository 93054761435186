import android_icon from '@images/android_white.svg'
import ios_icon from '@images/apple.svg'
import web_icon from '@images/chrome_white.svg'
import { MOBILE_BREAK_POINT } from '@styles/styles'
import React from 'react'
import styled from 'styled-components'

const platform_data = [
  {
    icon: web_icon,
    title: 'Web',
    body: 'React JS',
  },
  {
    icon: ios_icon,
    title: 'IOS',
    body: 'React Native / Expo',
  },
  {
    icon: android_icon,
    title: 'Android',
    body: 'React Native / Expo',
  },
]

interface IsProps {
  visible: boolean
  inDelay?: number
}

export const PlatformDetails = ({ visible, inDelay }: IsProps) => {
  return (
    <PlatformsWrapper>
      {platform_data.map(({ icon, title, body }, i) => (
        <PlatformItemWrapper key={title} i={i} {...{ visible, inDelay }}>
          {/* <PlatformIconContainer> */}
          <PlatformIcon src={icon} />
          {/* </PlatformIconContainer> */}
          <PlatformBodyContainer>
            <PlatformTitle>{title}</PlatformTitle>
            <PlatformBody>{body}</PlatformBody>
          </PlatformBodyContainer>
        </PlatformItemWrapper>
      ))}
    </PlatformsWrapper>
  )
}

const PlatformsWrapper = styled.div`
  position: absolute;
  left: ${(props) => props.theme.paddings.extendedContent}px;
  bottom: 40px;
`

interface ItemProps {
  visible: boolean
  i: number
  inDelay?: number
}

const PlatformItemWrapper = styled.div<ItemProps>`
  display: flex;
  margin: 15px 0;
  transition: 500ms cubic-bezier(0.61, 1, 0.88, 1);
  transition-delay: ${(props) => (props.inDelay || 0) + props.i * 100}ms;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transform: translateY(${(props) => (props.visible ? 0 : 20)}px);
`

const PlatformIcon = styled.img`
  margin-right: 1rem;
  width: 2.2rem;
  height: auto;
  /* width: auto; */
  object-fit: contain;
  opacity: 0.9;
`

const PlatformBodyContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
`

const PlatformTitle = styled.h3`
  font-family: ${(props) => props.theme.fontFamily.regular};
  font-weight: 600;
  font-size: ${(props) => props.theme.fontSize.small};
  color: ${(props) => props.theme.colors.text2};
  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    font-size: ${(props) => props.theme.mobile.fontSize.small};
  }
`

const PlatformBody = styled.p`
  font-family: ${(props) => props.theme.fontFamily.regular};
  font-weight: 200;
  font-size: ${(props) => props.theme.fontSize.small};
  color: ${(props) => props.theme.colors.text2};
  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    font-size: ${(props) => props.theme.mobile.fontSize.small};
  }
`
