import { MOBILE_BREAK_POINT, WIDTHS } from '@styles/styles'
import React from 'react'
import styled from 'styled-components'

type Paragraph = {
  text: string
  link?: string
}[]

export interface SectionMainTextParams {
  header: string
  subheader: string
  body: Paragraph[]
}

interface IsProps extends SectionMainTextParams {
  visible?: boolean
  /**A number in ms to delay the visibility in animation */
  inDelay?: number
  /**Styles to overwrite the wrapper, such as max-width etc */
  wrapperStyles?: React.CSSProperties
}

export const SectionMainText = ({
  visible = true,
  inDelay,
  wrapperStyles,
  header,
  subheader,
  body,
}: IsProps) => {
  return (
    <Wrapper style={wrapperStyles}>
      <SectionHeader visible={visible} inDelay={inDelay}>
        {header}
      </SectionHeader>
      <SectionSubheader visible={visible} inDelay={inDelay}>
        {subheader}
      </SectionSubheader>
      {body.map((paragraph, i) => (
        <BodyTextContainer key={i} visible={visible} inDelay={inDelay} i={i}>
          <SectionBodyText key={i}>
            {paragraph.map(({ text, link }, ii) =>
              link ? (
                <SectionBodyTextLink
                  key={ii}
                  href={link}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {text}
                </SectionBodyTextLink>
              ) : (
                text
              )
            )}
          </SectionBodyText>
        </BodyTextContainer>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    width: 100%;
    max-width: ${WIDTHS.maxMobile};
  }
`

interface VisibleProps {
  visible: boolean
  inDelay?: number
}

const t_duration = 600
const t_delay = 100
const easing = 'cubic-bezier(0.61, 1, 0.88, 1)'

export const sectionMainTextAnimParams = {
  duration: t_duration,
  delay: t_delay,
  totalDuration: t_duration + t_delay * 2,
  easing,
}

export const SectionHeader = styled.h1<VisibleProps>`
  font-family: ${(props) => props.theme.fontFamily.regular};
  font-weight: 700;
  font-size: ${(props) => props.theme.fontSize.header};
  color: ${(props) => props.theme.colors.primary};
  transition: ${t_duration}ms ${easing};
  transition-delay: ${(props) => (props.visible ? props.inDelay || 0 : 0)}ms;
  transform: translateY(${(props) => (props.visible ? 0 : 20)}px);
  opacity: ${(props) => (props.visible ? 1 : 0)};
  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    font-size: ${(props) => props.theme.mobile.fontSize.header};
  }
`

export const SectionSubheader = styled.h2<VisibleProps>`
  font-family: ${(props) => props.theme.fontFamily.regular};
  font-weight: 500;
  font-size: ${(props) => props.theme.fontSize.subheader};
  color: ${(props) => props.theme.colors.text1};
  margin-top: 5px;
  transition: ${t_duration}ms ${easing};
  transition-delay: ${(props) =>
    props.visible ? (props.inDelay || 0) + t_delay : 0}ms;
  transform: translateY(${(props) => (props.visible ? 0 : 20)}px);
  opacity: ${(props) => (props.visible ? 1 : 0)};
  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    font-size: ${(props) => props.theme.mobile.fontSize.subheader};
  }
`

interface BodyTextContainerProps extends VisibleProps {
  i: number
}

const BodyTextContainer = styled.div<BodyTextContainerProps>`
  transition: ${t_duration}ms ${easing};
  transition-delay: ${(props) =>
    props.visible ? (props.inDelay || 0) + t_delay * (2 + props.i) : 0}ms;
  transform: translateY(${(props) => (props.visible ? 0 : 20)}px);
  opacity: ${(props) => (props.visible ? 1 : 0)};
`

export const SectionBodyText = styled.p`
  font-family: ${(props) => props.theme.fontFamily.regular};
  font-size: ${(props) => props.theme.fontSize.body};
  color: ${(props) => props.theme.colors.text2};
  font-weight: 400;
  white-space: pre-line;
  margin-top: 20px;
  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    font-size: ${(props) => props.theme.mobile.fontSize.body};
  }
`

export const SectionBodyTextLink = styled.a`
  color: ${(props) => props.theme.colors.primary};
  cursor: pointer;
  transition: 350ms;
  text-decoration: none;
  &:hover {
    color: ${(props) => props.theme.colors.secondary};
  }
`
