import mySatellite from '@images/MySatellite.svg'
import mySatellite2 from '@images/MySatellite_2.svg'
import { COLORS } from '@styles/styles'
import React from 'react'
import styled, { keyframes } from 'styled-components'

interface IsProps {
  isVisible: boolean
}

export const ContactBG = ({ isVisible }: IsProps) => {
  return (
    <Wrapper>
      <OrbitCircle isVisible={isVisible} size={95}>
        <SatelliteImage src={mySatellite} />
        <SatelliteBeacon isVisible={isVisible} />
      </OrbitCircle>
      <OrbitCircle2 isVisible={isVisible} size={75}>
        {/* <SatelliteBeam /> This isn't looking nice */}
        <SatelliteImage src={mySatellite2} />
        <SatelliteBeacon isVisible={isVisible} altColor />
      </OrbitCircle2>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  overflow: hidden;
`

interface OrbitCircleProps {
  size: number
  isVisible: boolean
}

const orbitAnim = keyframes`
0% {
  transform: translateZ(0) scale(1) rotate(0deg);
}
50% {
  transform: translateZ(0) scale(0.7) rotate(180deg);
}
100% {
  transform: translateZ(0) scale(1) rotate(360deg);
}
`

const orbitAnim2 = keyframes`
0% {
  transform: translateZ(0) scale(1) rotate(180deg);
}
50% {
  transform: translateZ(0) scale(0.7) rotate(360deg);
}
100% {
  transform: translateZ(0) scale(1) rotate(540deg);
}
`

const OrbitCircle = styled.div<OrbitCircleProps>`
  position: absolute;
  top: calc(50% - ${(props) => props.size / 2}vh);
  left: calc(50% - ${(props) => props.size / 2}vh);
  height: ${(props) => props.size}vh;
  width: ${(props) => props.size}vh;
  text-align: center;
  border-radius: 50%;
  /* border: 1px solid red; */
  animation-name: ${orbitAnim};
  animation-play-state: ${(props) => (props.isVisible ? 'running' : 'paused')};
  animation-duration: 15s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`

/**Same as above, just rotation applied and a different anim speed */
const OrbitCircle2 = styled(OrbitCircle)`
  transform: rotate(180deg);
  animation-name: ${orbitAnim2};
  animation-play-state: ${(props) => (props.isVisible ? 'running' : 'paused')};
  /* animation-duration: 14s; */
`

const SatelliteImage = styled.img`
  /* position: absolute ;
  top: 0; */
  height: 7vh;
  width: auto;
  opacity: 0.3;
`

const beaconSize = 8

const grow = keyframes`
  0% {
    transform: scale(.1);
  }
  
  100% {
    transform: scale(1);
  }
`

const active = keyframes`
  0%{
    transform:scale(.1);
    opacity:1;
  }
  70%{
    transform:scale(4);
    opacity:0;
  }
  100%{
    opacity:0;
  }
`

interface BeaconProps {
  altColor?: boolean
  isVisible: boolean
}

const SatelliteBeacon = styled.span<BeaconProps>`
  position: absolute;
  left: calc(50% - ${beaconSize / 2}px);
  top: -${beaconSize * 2}px;
  height: ${beaconSize}px;
  width: ${beaconSize}px;
  background-color: ${(props) =>
    props.altColor ? props.theme.colors.secondary : props.theme.colors.primary};
  border-radius: 50%;
  animation: ${grow} 0.6s 1 linear;
  opacity: 0.8;
  animation-play-state: ${(props) => (props.isVisible ? 'running' : 'paused')};

  &:before {
    position: absolute;
    content: '';
    height: ${beaconSize}px;
    width: ${beaconSize}px;
    left: 0;
    top: 0;
    background-color: transparent;
    border-radius: 50%;
    box-shadow: 0px 0px 2px 2px
      ${(props) =>
        props.altColor
          ? props.theme.colors.secondary
          : props.theme.colors.primary};
    animation: ${active} 3s infinite linear;
    animation-play-state: ${(props) =>
      props.isVisible ? 'running' : 'paused'};
  }
`

const beamSize = 2

const beamAnim = keyframes`
  0% {
    height: 0%;
    transform: translateY(0);
    background-color: ${COLORS.secondary};
  }
  
  50% {
    height: 20%;
  }
  
  100% {
    height: 0%;
    transform: translateY(68vh);
    background-color: ${COLORS.primary};
  }

`

const SatelliteBeam = styled.span`
  position: absolute;
  left: calc(50% - ${beamSize}px);
  top: 7vh;
  width: ${beamSize}px;
  height: 0%;
  background-color: ${COLORS.secondary};
  animation: ${beamAnim} 10s infinite linear alternate;
  transform-origin: top;
  opacity: 0.2;
  /* background-image: linear-gradient(
    180deg,
    ${COLORS.secondary}00 15%,
    ${COLORS.secondary}1A,
    ${COLORS.primary}1A,
    ${COLORS.primary}00 85%
  ); */
  /* background-size: 100% 200%; */
`
