import appstoreIcon from '@images/ios.svg'
import playstoreIcon from '@images/playstore.svg'
import { MOBILE_BREAK_POINT } from '@styles/styles'
import React from 'react'
import styled from 'styled-components'

const data = {
  android: {
    icon: playstoreIcon,
    topText: 'Get it on',
    mainText: 'Google Play',
  },
  ios: {
    icon: appstoreIcon,
    topText: 'Download on the',
    mainText: 'App Store',
  },
}

interface IsProps {
  store: 'android' | 'ios'
  link?: string
  disabled?: boolean
  marginRight?: boolean
}

export const AppStoreButton = ({
  store,
  link,
  disabled,
  marginRight,
}: IsProps) => {
  const { icon, topText, mainText } = data[store]

  /**Required to prevent bubbling to the mobile card causing the description to also activate */
  const stopPropagation = (e: React.MouseEvent<HTMLElement>) =>
    e.stopPropagation()

  return (
    <Wrapper
      {...{ disabled, marginRight }}
      href={link}
      target='_blank'
      rel='noopener noreferrer'
      onClick={stopPropagation}
    >
      <ButtonIcon src={icon} />
      <TextContainer>
        <TopText>{topText}</TopText>
        <MainText>{mainText}</MainText>
      </TextContainer>
    </Wrapper>
  )
}

interface WrapperProps {
  disabled?: boolean
  marginRight?: boolean
}

const Wrapper = styled.a<WrapperProps>`
  padding: 10px 20px;
  /* min-height: 2.5rem; */
  /* min-width: 8rem; */
  background-color: black;
  border: 2px solid white;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${(props) => (props.disabled ? 0.8 : 1)};
  margin-right: ${(props) => (props.marginRight ? '2em' : 0)};
  text-decoration: none;
  transition: opacity 250ms;
  opacity: 1;
  &:hover {
    opacity: 0.8;
  }
  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    padding: 0.5em 1em;
    margin-right: ${(props) => (props.marginRight ? '1em' : 0)};
  }
`

const ButtonIcon = styled.img`
  height: 1.3em;
  width: auto;
  object-fit: contain;
  margin-right: 20px;

  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    height: 1em;
    margin-right: 4px;
  }
`

const TextContainer = styled.div``

const TopText = styled.p`
  font-family: ${(props) => props.theme.fontFamily.regular};
  font-weight: 200;
  font-size: 0.6em;
  color: white;
  white-space: nowrap;
  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    font-size: 0.4em;
  }
`

const MainText = styled.p`
  font-family: ${(props) => props.theme.fontFamily.regular};
  font-weight: 400;
  font-size: 0.9em;
  color: white;
  white-space: nowrap;
  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    font-size: 0.7em;
  }
`
