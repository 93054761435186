import React from 'react'
import { useInView } from 'react-intersection-observer'
import styled from 'styled-components'
import { Sphere } from './_sphere'
// import { BackgroundGrid } from './_backgroundGrid'
// import { BackgroundStars } from './_backgroundStars'

interface IsProps {
  /**Used to prevent the animation from starting while the loading overlay is still visible */
  showLoading: boolean
}

export const HomeBackground = React.memo(({ showLoading }: IsProps) => {
  const { ref, inView } = useInView()

  return (
    <Wrapper ref={ref}>
      <Sphere inView={inView && !showLoading} />
      {/* <BackgroundGrid {...{ inView }} /> */}
      {/* <BackgroundStars {...{ inView }} /> */}
      {/* <BackgroundSwirl {...{ inView }} /> */}
    </Wrapper>
  )
})

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`
