import arrowDown from '@images/arrow_down.svg'
import { AnimatedCTAButton } from '@shared-components/animatedCTAButton'
import { MOBILE_BREAK_POINT } from '@styles/styles'
import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'
import { HomeBackground } from './background/homeBackground'
import { PlatformDetails } from './platformDetails'

interface IsProps {
  setShowNavbarBG: (show: boolean) => void
  onReadMoreClick: () => void
  showLoading: boolean
}

export const Home = ({
  setShowNavbarBG,
  showLoading,
  onReadMoreClick,
}: IsProps) => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${MOBILE_BREAK_POINT}px)`,
  })

  const { ref, inView } = useInView({ threshold: 0.15 })

  useEffect(() => {
    setShowNavbarBG(!inView)
  }, [inView])

  return (
    <Wrapper {...{ ref }}>
      <HomeBackground {...{ showLoading }} />
      {/* <Container> */}
      <BodyContainer>
        <Header visible={!showLoading}>JANATH PERERA</Header>
        <BodyText visible={!showLoading}>
          {`Creating seamless interactions between\nhumans and machines`}
        </BodyText>
        {/* <CTA visible={!showLoading}>Read More</CTA> */}
        <CTAContainer visible={!showLoading}>
          <AnimatedCTAButton
            buttonText={'Read More'}
            hoverImage={arrowDown}
            onPress={onReadMoreClick}
          />
        </CTAContainer>
      </BodyContainer>
      {/* </Container> */}
      {!isMobile && <PlatformDetails visible={!showLoading} inDelay={500} />}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  /* background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.9) 80%,
    rgba(0, 0, 0, 0.5) 90%,
    rgba(0, 0, 0, 0) 100%
  ); */
  /* background-image: radial-gradient(
    ellipse at 50% 18%,
    rgba(0, 0, 0, 1) 75%,
    rgba(0, 0, 0, 0) 100%
  ); */
`

const Container = styled.div`
  position: relative;
  display: flex;
  /* flex: 1; */
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: ${(props) => props.theme.widths.maxDesktop};
  justify-content: center;
  align-items: center;
  /* background-color: teal; */
`

const BodyContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-left: ${(props) => props.theme.paddings.extendedContent}px; */
  /* margin-top: 7%; */
  z-index: 2;
`

interface VisibleProps {
  visible: boolean
}

const inDelay = 2000
const tDuration = 600
const tDelay = 100
const easing = 'cubic-bezier(0.61, 1, 0.88, 1)'

const Header = styled.h1<VisibleProps>`
  font-family: ${(props) => props.theme.fontFamily.regular};
  font-weight: 800;
  font-size: ${(props) => props.theme.fontSize.jumbo};
  /* font-size: clamp(1.5rem, 2.5vw, 4rem); */
  color: ${(props) => props.theme.colors.text1};
  letter-spacing: 0.25em;
  transition: ${tDuration}ms ${easing};
  transition-delay: ${inDelay}ms;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transform: translateY(${(props) => (props.visible ? 0 : 20)}px);
  /* margin-bottom: 5px; */
  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    font-size: ${(props) => props.theme.mobile.fontSize.jumbo};
    letter-spacing: 0.4vw;
  }
`

const BodyText = styled.p<VisibleProps>`
  font-family: ${(props) => props.theme.fontFamily.regular};
  font-weight: 200;
  font-size: ${(props) => props.theme.fontSize.body};
  color: ${(props) => props.theme.colors.text2};
  white-space: pre-line;
  text-align: center;
  margin: 15px 0 5px 0;
  transition: ${tDuration}ms ${easing};
  transition-delay: ${inDelay + tDelay}ms;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transform: translateY(${(props) => (props.visible ? 0 : 20)}px);
  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    font-size: ${(props) => props.theme.mobile.fontSize.body};
    font-weight: 400;
  }
`

const CTAContainer = styled.div<VisibleProps>`
  transition: ${tDuration}ms ${easing};
  transition-delay: ${inDelay + tDelay * 2}ms;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transform: translateY(${(props) => (props.visible ? 0 : 20)}px);
`
