import { MOBILE_BREAK_POINT } from '@styles/styles'
import React from 'react'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'
import email_icon from '../../../../images/email.svg'
import git_icon from '../../../../images/git.svg'
import linkedin_icon from '../../../../images/linkedin.svg'
import stackoverflow_icon from '../../../../images/stackoverflow.svg'

const data = [
  {
    icon: email_icon,
    link: 'mailto:inquiries@janathperera.com',
  },
  {
    icon: git_icon,
    link: 'https://github.com/jdlk07',
  },
  {
    icon: linkedin_icon,
    link: 'https://www.linkedin.com/in/janath-perera-6121a7175/',
  },
  {
    icon: stackoverflow_icon,
    link: 'https://stackoverflow.com/users/9885094/jdlk07',
  },
]

interface IsProps {
  visible: boolean
}

export const FixedContactBar = ({ visible }: IsProps) => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${MOBILE_BREAK_POINT}px)`,
  })

  if (isMobile) return null

  return (
    <Wrapper isVisible={visible}>
      {data.map((item, i) => (
        <IconButton
          key={i}
          {...{ i }}
          href={item.link}
          target='_blank'
          rel='noopener noreferrer'
        >
          <IconButtonContainer {...{ isVisible: visible, i }}>
            <IconImage src={item.icon} />
          </IconButtonContainer>
        </IconButton>
      ))}
      <Bar {...{ isVisible: visible }} />
    </Wrapper>
  )
}

const icon_size = 25

interface WrapperProps {
  isVisible: boolean
}

const Wrapper = styled.div<WrapperProps>`
  position: fixed;
  bottom: 0;
  right: ${(props) => props.theme.paddings.extendedContent}px;
  /* width: 30px; */
  /* height: 200px; */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  pointer-events: ${(props) => (props.isVisible ? 'auto' : 'none')};
  z-index: 10;
`

interface IconButtonProps {
  isVisible?: boolean
  i: number
}

const IconButton = styled.a<IconButtonProps>`
  height: ${icon_size}px;
  width: ${icon_size}px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: teal; */
  margin-bottom: 20px;
  cursor: pointer;
  /* color: teal; */
  transition: 250ms;
  opacity: 0.3;

  &:hover {
    transform: translateY(-20%);
    opacity: 0.5;
  }
`

/**
 * Extra container to separate the hover and visibility animations. Else the transition-delay gets applied to the on un hover event too =(
 */
const IconButtonContainer = styled.div<IconButtonProps>`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  transition: 500ms;
  transition-delay: ${(props) => (data.length - props.i) * 100 + 250}ms;
  transform: translateY(${(props) => (props.isVisible ? 0 : 100)}%);
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
`

const IconImage = styled.img`
  width: 75%;
  height: auto;
  object-fit: contain;
  /* color: lightsalmon; */
`

interface BarProps {
  isVisible: boolean
}

const Bar = styled.span<BarProps>`
  width: 1px;
  height: 15vh;
  background-color: rgba(255, 255, 255, 0.25);
  transition: 500ms;
  transform: translateY(${(props) => (props.isVisible ? 9 : '100%')});
`
