import { BACKGROUND_COLORS } from '@styles/styles'
import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'

// # Shortcuts
let fnRnd = Math.random
let fnRnd2 = () => 2.0 * fnRnd() - 1.0
let fnCos = Math.cos
let fnACos = Math.acos
let fnSin = Math.sin
// # Sphere Settings
// let iRadiusSphere = window.innerHeight / 4
// let iProjSphereX = window.innerWidth / 2
// let iProjSphereY = window.innerHeight / 2
// # Particle Settings
let fMaxAX = 0.1
let fMaxAY = 0.1
let fMaxAZ = 0.1
let fStartVX = 0.001
let fStartVY = 0.001
let fStartVZ = 0.001
let fAngle = 0.0
let fSinAngle = 0.0
let fCosAngle = 0.0

//WTF is this?!?!?

const params = {
  iFramesToRotate: 3000.0, //Speed of the spheres rotation
  iPerspective: 285, //Camera, lower number takes you inside the sphere, smaller takes you further away
  iNewParticlePerFrame: 10, //Number of visible particles per frame. Reducing this will also reduce the particles that make up the sphere.
  fGrowDuration: 150.0, //Duration of the spheres initial grow
  fWaitDuration: 50.0, //The time before the first particle starts falling off
  fShrinkDuration: 100.0, //The lifespan of a particle once it leaves the sphere.
}

// let aColor = [255, 128, 128]
// let aColor = [20, 225, 188]
let aColor = [216, 17, 172]

//And this??
let fVX = (2.0 * Math.PI) / params.iFramesToRotate

let oRadGrad = null
// let ctxRender = nCanvasRender.getContext('2d');
let oRender: { pFirst: null | Particle } = {
  pFirst: null,
}
let oBuffer: { pFirst: null | Particle } = {
  pFirst: null,
}

function fnSwapList(
  p: Particle,
  oSrc: { pFirst: null | Particle },
  oDst: { pFirst: null | Particle }
) {
  if (p != null) {
    if (oSrc.pFirst === p) {
      oSrc.pFirst = p.pNext
      if (p.pNext != null) {
        p.pNext.pPrev = null
      }
    } else {
      if (p.pPrev !== null) {
        p.pPrev.pNext = p.pNext
      }
      if (p.pNext != null) {
        p.pNext.pPrev = p.pPrev
      }
    }
  } else {
    p = new Particle()
  }
  p.pNext = oDst.pFirst
  if (oDst.pFirst != null) {
    oDst.pFirst.pPrev = p
  }
  oDst.pFirst = p
  p.pPrev = null
  return p
}

class Particle {
  fX: number
  fY: number
  fZ: number
  fVX: number
  fVY: number
  fVZ: number
  fAX: number
  fAY: number
  fAZ: number
  fProjX: number
  fProjY: number
  fRotX: number
  fRotZ: number
  pPrev: null | Particle //Is this supposed to be a number?
  pNext: null | Particle //Is this supposed to be a number?
  fAngle: number
  fForce: number
  fAlpha: number
  fGrowDuration: number
  fWaitDuration: number
  fShrinkDuration: number
  fRadiusCurrent: number
  iFramesAlive: number
  bIsDead: boolean
  iRadiusSphere: number
  iProjSphereX: number
  iProjSphereY: number

  constructor() {
    // # Current Position
    this.fX = 0.0
    this.fY = 0.0
    this.fZ = 0.0
    // # Current Velocity
    this.fVX = 0.0
    this.fVY = 0.0
    this.fVZ = 0.0
    // # Current Acceleration
    this.fAX = 0.0
    this.fAY = 0.0
    this.fAZ = 0.0
    // # Projection Position
    this.fProjX = 0.0
    this.fProjY = 0.0
    // # Rotation
    this.fRotX = 0.0
    this.fRotZ = 0.0
    // # double linked list
    this.pPrev = null
    this.pNext = null

    this.fAngle = 0.0
    this.fForce = 0.0
    this.fAlpha = 0

    this.fGrowDuration = 0.0
    this.fWaitDuration = 0.0
    this.fShrinkDuration = 0.0

    this.fRadiusCurrent = 0.0

    this.iFramesAlive = 0
    this.bIsDead = false

    this.iRadiusSphere = Math.floor(
      Math.min(230, window.innerHeight / 4, window.innerWidth / 3)
    ) //Hard coded a min value because it grows too big on larger screen, not proportionate to the screen for some reason

    this.iProjSphereX = window.innerWidth / 2
    this.iProjSphereY = window.innerHeight / 2
  }

  initialize() {
    this.fAngle = fnRnd() * Math.PI * 2
    this.fForce = fnACos(fnRnd2())
    this.fAlpha = 0
    this.bIsDead = false
    this.iFramesAlive = 0
    this.fX = this.iRadiusSphere * fnSin(this.fForce) * fnCos(this.fAngle)
    this.fY = this.iRadiusSphere * fnSin(this.fForce) * fnSin(this.fAngle)
    this.fZ = this.iRadiusSphere * fnCos(this.fForce)
    this.fVX = fStartVX * this.fX
    this.fVY = fStartVY * this.fY
    this.fVZ = fStartVZ * this.fZ
    this.fGrowDuration =
      params.fGrowDuration + fnRnd2() * (params.fGrowDuration / 4.0)
    this.fWaitDuration =
      params.fWaitDuration + fnRnd2() * (params.fWaitDuration / 4.0)
    this.fShrinkDuration =
      params.fShrinkDuration + fnRnd2() * (params.fShrinkDuration / 4.0)
    this.fAX = 0.0
    this.fAY = 0.0
    this.fAZ = 0.0
  }

  update() {
    if (this.iFramesAlive > this.fGrowDuration + this.fWaitDuration) {
      this.fVX += this.fAX + fMaxAX * fnRnd2()
      this.fVY += this.fAY + fMaxAY * fnRnd2()
      this.fVZ += this.fAZ + fMaxAZ * fnRnd2()
      this.fX += this.fVX
      this.fY += this.fVY
      this.fZ += this.fVZ
    }
    this.fRotX = fCosAngle * this.fX + fSinAngle * this.fZ
    this.fRotZ = -fSinAngle * this.fX + fCosAngle * this.fZ
    this.fRadiusCurrent = Math.max(
      0.01,
      params.iPerspective / (params.iPerspective - this.fRotZ)
    )
    this.fProjX = this.fRotX * this.fRadiusCurrent + this.iProjSphereX
    this.fProjY = this.fY * this.fRadiusCurrent + this.iProjSphereY
    this.iFramesAlive += 1
    if (this.iFramesAlive < this.fGrowDuration) {
      this.fAlpha = (this.iFramesAlive * 1.0) / this.fGrowDuration
    } else if (this.iFramesAlive < this.fGrowDuration + this.fWaitDuration) {
      this.fAlpha = 1.0
    } else if (
      this.iFramesAlive <
      this.fGrowDuration + this.fWaitDuration + this.fShrinkDuration
    ) {
      this.fAlpha =
        ((this.fGrowDuration +
          this.fWaitDuration +
          this.fShrinkDuration -
          this.iFramesAlive) *
          1.0) /
        this.fShrinkDuration
    } else {
      this.bIsDead = true
    }
    if (this.bIsDead === true) {
      fnSwapList(this, oRender, oBuffer)
    }
    this.fAlpha *= Math.min(1.0, Math.max(0.5, this.fRotZ / this.iRadiusSphere))
    this.fAlpha = Math.min(1.0, Math.max(0.0, this.fAlpha))
  }

  resize(canvasWidth: number, canvasHeight: number) {
    this.iRadiusSphere = Math.floor(
      Math.min(230, window.innerHeight / 4, window.innerWidth / 3)
    )
    this.iProjSphereX = canvasWidth / 2
    this.iProjSphereY = canvasHeight / 2

    this.initialize()
  }
}

// function fnRender(ctx: CanvasRenderingContext2D) {
//   let iCount: number = 0
//   let p: Particle | null = null

//   ctx.fillStyle = '#000'
//   ctx.fillRect(0, 0, w, h)
//   p = oRender.pFirst
//   iCount = 0
//   while (p !== null) {
//     ctx.fillStyle = 'rgba(' + aColor.join(',') + ',' + p.fAlpha.toFixed(4) + ')'
//     ctx.beginPath()
//     ctx.arc(p.fProjX, p.fProjY, p.fRadiusCurrent, 0, 2 * Math.PI, false)
//     ctx.closePath()
//     ctx.fill()
//     p = p.pNext
//     iCount += 1
//   }
// }

interface IsProps {
  inView: boolean
}

export const Sphere = ({ inView }: IsProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    const canvas = canvasRef.current!
    const ctx = canvas?.getContext('2d')!

    let animationFrameId: number = 0

    canvas.width = window.innerWidth
    canvas.height = window.innerHeight

    var iAddParticle, iCount, pNext
    let p: Particle | null = null

    const animate = (timeStamp: number) => {
      // oStats.begin();
      fAngle = (fAngle + fVX) % (2.0 * Math.PI)
      fSinAngle = fnSin(fAngle)
      fCosAngle = fnCos(fAngle)
      iAddParticle = 0
      iCount = 0

      while (iAddParticle++ < params.iNewParticlePerFrame) {
        p = fnSwapList(oBuffer.pFirst!, oBuffer, oRender)
        p.initialize()
      }
      p = oRender.pFirst
      while (p != null) {
        pNext = p.pNext
        p.update()
        p = pNext
        iCount++
      }
      // fnRender(ctx)
      // ctx.fillStyle = 'rgba(0, 0, 0, 0.6)'
      ctx.fillStyle = BACKGROUND_COLORS.screen
      ctx.fillRect(0, 0, canvas.width, canvas.height)
      p = oRender.pFirst
      iCount = 0
      while (p !== null) {
        ctx.fillStyle =
          'rgba(' + aColor.join(',') + ',' + p.fAlpha.toFixed(4) + ')'
        ctx.beginPath()
        ctx.arc(p.fProjX, p.fProjY, p.fRadiusCurrent, 0, 2 * Math.PI, false)
        ctx.closePath()
        ctx.fill()
        p = p.pNext
        iCount += 1
      }

      // oStats.end();
      // return fnRequestAnimationFrame(function() {
      //   return nextFrame(ctx);
      // });
      // gridEffect.draw(ctx)

      animationFrameId = window.requestAnimationFrame(animate)
    }
    if (inView) {
      animate(0)
    } else if (animationFrameId) {
      window.cancelAnimationFrame(animationFrameId)
    }

    const resizeEvent = () => {
      canvas.width = window.innerWidth
      canvas.height = window.innerHeight

      while (p !== null) {
        p?.resize(canvas.width, canvas.height)
      }
    }

    window.addEventListener('resize', resizeEvent)

    return () => {
      window.removeEventListener('resize', resizeEvent)
      window.cancelAnimationFrame(animationFrameId)
    }
  }, [inView])

  return <Canvas ref={canvasRef} />
}

const Canvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0.6;
  z-index: 0;
`

// const gui = new dat.GUI()
// gui.add(params, 'fGrowDuration').min(10).max(500).step(1)
// gui.add(params, 'fWaitDuration').min(10).max(500).step(1)
// gui.add(params, 'fShrinkDuration').min(10).max(500).step(1)
// gui.add(params, 'iPerspective').min(150).max(1000).step(1)
// gui.add(params, 'iNewParticlePerFrame').min(1).max(20).step(1)
// gui
//   .add(params, 'iFramesToRotate')
//   .min(50)
//   .max(2500)
//   .step(50)
//   .onChange(function () {
//     return (fVX = (2.0 * Math.PI) / params.iFramesToRotate)
//   })
