import navButtonIcon from '@images/arrow_right_circle.svg'
import React, { useState } from 'react'
import { useInView } from 'react-intersection-observer'
import styled from 'styled-components'
import { work_data } from '../data'
import { WorkCard, workCardWidth } from './workCard'

export const WorkDesktop = () => {
  const { ref, inView } = useInView({ threshold: 0.4 })

  const [active_i, setActive_i] = useState(0)
  const [isAnimating, setIsAnimating] = useState(false)

  const onNextPress = () => {
    if (!isAnimating) {
      setIsAnimating(true)
      setActive_i((s) => (s === work_data.length - 1 ? 0 : s + 1))
    }
  }

  const onPrevPress = () => {
    if (!isAnimating) {
      setIsAnimating(true)
      setActive_i((s) => (s === 0 ? work_data.length - 1 : s - 1))
    }
  }

  let prev_i = active_i == 0 ? work_data.length - 1 : active_i - 1

  let next_i = active_i == work_data.length - 1 ? 0 : active_i + 1

  return (
    <Wrapper {...{ ref }}>
      <NavButton disabled={isAnimating} onClick={onPrevPress}>
        <NavButtonIcon left src={navButtonIcon} />
      </NavButton>
      {work_data.map((data, i) => (
        <WorkCard
          data={data}
          active={active_i == i}
          previous={prev_i === i}
          next={next_i === i}
          setIsAnimating={setIsAnimating}
        />
      ))}
      <NavButton disabled={isAnimating} onClick={onNextPress}>
        <NavButtonIcon src={navButtonIcon} />
      </NavButton>
    </Wrapper>
  )
}

const transitionDelay = 150 //ms
const transitionTime = 500 //ms
const t_transitionTime = transitionDelay * 2 + transitionTime
const easing = 'cubic-bezier(0.25, 1, 0.5, 1)'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  /* padding: 10rem 0; */
  display: flex;
  /* flex: 1; */
  flex-direction: row;
  /* align-items: center; */
  justify-content: space-between;
  /* background-color: green; */
`

const NavButton = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  width: calc((100% - ${workCardWidth}) / 2);
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 5;
`

interface ButtonIconProps {
  left?: boolean
}

const NavButtonIcon = styled.img<ButtonIconProps>`
  width: 35%;
  height: auto;
  opacity: 0.5;
  transition: opacity 250ms;
  transform: rotate(${(props) => (props.left ? '180deg' : '0')});

  ${NavButton}:hover & {
    opacity: 1;
  }
`
