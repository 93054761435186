import bumprScreensMobile from '@images/work/Bumpr.png'
import bumprScreens from '@images/work/Bumpr1.png'
import BumprBg from '@images/work/Bumpr_bg.png'
import bumprLogo from '@images/work/Bumpr_logo.png'
import gymJunkieScreensMobile from '@images/work/GymJunkie.png'
import gymJunkieScreens from '@images/work/GymJunkie1.png'
import gymJunkieBg from '@images/work/GymJunkie_bg.png'
import gymJunkieLogo from '@images/work/GymJunkie_logo.png'
import utilifyScreensMobile from '@images/work/Utilify.png'
import utilifyScreens from '@images/work/Utilify1.png'
import utilifyBg from '@images/work/Utilify_bg.png'
import utilifyLogo from '@images/work/Utilify_logo.png'

export interface WorkData {
  header: string
  subheader: string
  header_fontFamily?: string
  header_color?: string
  header_fontWeight?: number
  subheader_fontFamily?: string
  app_color: string
  logo: string
  image: string
  imageMobile: string
  backgroundImage: string
  platformText: string
  platforms: {
    android: boolean
    ios: boolean
    web: boolean
  }
  body: string
  playStore?: string
  appStore?: string
  website?: string
}

export const work_data: WorkData[] = [
  {
    header: 'GYM JUNKIE',
    subheader: 'Not just another gym journal',
    header_fontFamily: 'orbitron',
    subheader_fontFamily: 'poppins',
    app_color: '#29BAE8',
    logo: gymJunkieLogo,
    image: gymJunkieScreens,
    imageMobile: gymJunkieScreensMobile,
    backgroundImage: gymJunkieBg,
    platformText: 'Mobile App (Android / IOS)',
    platforms: {
      android: true,
      ios: true,
      web: false,
    },
    body: `What started as a passion project over 3 years ago, evolved into one of my most loved projects.
      
      I've been working out for over 15 years but could never find a convenient way to track my workouts until now. Gym Junkie provides you with dynamic scores and targets so that you're always training your best!`,
    // appStore: 'disabled',
    playStore:
      'https://play.google.com/store/apps/details?id=com.gymjunkieapp.gymjunkie',
  },
  {
    header: 'Bumpr',
    subheader: 'Cataloging App',
    header_fontFamily: 'jost',
    header_fontWeight: 700,
    subheader_fontFamily: 'jost',
    header_color: '#ea33ae',
    app_color: '#ea33ae',
    logo: bumprLogo,
    image: bumprScreens,
    imageMobile: bumprScreensMobile,
    backgroundImage: BumprBg,
    platformText: 'Mobile App (Android / IOS)',
    platforms: {
      android: true,
      ios: true,
      web: false,
    },
    body: `A cataloging app built for Castheads Magazine, one of the leading magazines in the world of diecast and scale models.
      
      From design to deployment, this app was a chance to help diecast enthusiasts catalog al of their models with ease. Letting them browse and track their vast collections online. Giving users a chance to show off their collection and ensure they don't buy any models previously purchased.`,
    appStore: 'https://apps.apple.com/us/app/bumpr-cataloging-app/id1635026776',
    playStore:
      'https://play.google.com/store/apps/details?id=com.castheadsmagazine.bumpr',
  },
  {
    header: 'Utilify',
    subheader: 'NFT Network Visualizer',
    header_fontFamily: 'orbitron',
    header_color: '#00d5f8',
    app_color: '#00d5f8',
    subheader_fontFamily: 'poppins',
    logo: utilifyLogo,
    image: utilifyScreens,
    imageMobile: utilifyScreensMobile,
    backgroundImage: utilifyBg,
    platformText: 'Web App (React JS)',
    platforms: {
      android: false,
      ios: false,
      web: true,
    },
    body: `The Utilify platform runs on the blockchain and provides NFT creators a way to connect with various apps and games to offer their users real value NFT Tokens.

    I had the opportunity to design and develop their Interactive Network Explorer which allows users to view all their assets across the Utilify Network.
    `,
    website: 'https://utilify.io/',
  },
]
