import at_icon from '@images/at_icon.svg'
import contactIcon from '@images/contact.svg'
import git_icon from '@images/git_colored.svg'
import linkedin_icon from '@images/linkedin_colored.svg'
import stackoverflow_icon from '@images/stackoverflow_colored.svg'
import { AnimatedCTAButton } from '@shared-components/animatedCTAButton'
import { sectionMainTextAnimParams } from '@shared-components/sectionMainText'
import { COLORS, MOBILE_BREAK_POINT } from '@styles/styles'
import React, { useEffect, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import styled from 'styled-components'
import { ContactBG } from './_contactBackground'

const data = [
  {
    icon: git_icon,
    href: 'https://github.com/jdlk07',
  },
  {
    icon: linkedin_icon,
    href: 'https://www.linkedin.com/in/janath-perera-6121a7175/',
  },
  {
    icon: stackoverflow_icon,
    href: 'https://stackoverflow.com/users/9885094/jdlk07',
  },
  {
    icon: at_icon,
    copyEmail: true,
    // href: 'https://stackoverflow.com/users/9885094/jdlk07',
    // onClick: () => navigator.clipboard.writeText('inquiries@janathperera.com'),
  },
]

interface IsProps {
  hideContactBar: (visible: boolean) => void
}

export const Contact = React.forwardRef(
  ({ hideContactBar }: IsProps, scrollRef: React.Ref<HTMLDivElement>) => {
    const popupTimer = useRef<NodeJS.Timeout>()

    const { ref, inView } = useInView({ threshold: 0.15 })
    const [bodyRef, showContent] = useInView({
      threshold: 0.5,
      triggerOnce: true,
    })

    const [showPopup, setPopup] = useState(false)

    const onCopyEmail = () => {
      try {
        navigator.clipboard.writeText('inquiries@janathperera.com')
        setPopup(true)
      } catch (error) {
        console.log('Failed to copy email ', error)
      }

      popupTimer.current = setTimeout(() => setPopup(false), 2000)
    }

    useEffect(() => {
      hideContactBar(inView)
    }, [inView])

    useEffect(() => {
      return () => {
        clearTimeout(popupTimer.current)
      }
    }, [])

    return (
      <Wrapper {...{ ref }}>
        <ContactBG isVisible={inView} />
        <BodyContainer ref={scrollRef}>
          <Header visible={showContent}>Get In Touch</Header>
          <BodyText ref={bodyRef} visible={showContent}>
            {`Feel free to get in touch if you have any
          questions, inquiries or just want to say hi!`}
          </BodyText>
          <CTAContainer visible={showContent}>
            <AnimatedCTAButton
              buttonText={'MESSAGE'}
              hoverImage={contactIcon}
              href={'mailto:inquiries@janathperera.com'}
            />
          </CTAContainer>

          <OtherLinksWrapper visible={showContent}>
            {data.map(({ icon, href, copyEmail }, i) => (
              <OtherLinksButton
                key={i}
                href={href}
                target={href ? '_blank' : undefined}
                rel={href ? 'noopener noreferrer' : undefined}
                onClick={copyEmail ? onCopyEmail : undefined}
              >
                <OtherLinkImage src={icon} />
              </OtherLinksButton>
            ))}
          </OtherLinksWrapper>
          <PopupContainer visible={showPopup}>
            <PopupText>Email Copied</PopupText>
          </PopupContainer>
        </BodyContainer>
      </Wrapper>
    )
  }
)

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const BodyContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: teal; */
  max-width: ${(props) => props.theme.widths.content};
`

interface VisibleProps {
  visible: boolean
}

const { duration, delay, easing } = sectionMainTextAnimParams

const Header = styled.h1<VisibleProps>`
  font-family: ${(props) => props.theme.fontFamily.regular};
  font-weight: 700;
  font-size: ${(props) => props.theme.fontSize.header};
  color: ${(props) => props.theme.colors.text1};
  margin-bottom: 1rem;
  transition: ${duration}ms ${easing};
  transform: translate3d(0, ${(props) => (props.visible ? 0 : 20)}px, 0);
  opacity: ${(props) => (props.visible ? 1 : 0)};
  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    font-size: ${(props) => props.theme.mobile.fontSize.header};
  }
`

const BodyText = styled.p<VisibleProps>`
  font-family: ${(props) => props.theme.fontFamily.regular};
  font-weight: 400;
  font-size: ${(props) => props.theme.fontSize.body};
  color: ${(props) => props.theme.colors.text2};
  white-space: pre-line;
  text-align: center;
  margin-bottom: 1rem;
  transition: ${duration}ms ${easing};
  transition-delay: ${delay}ms;
  transform: translate3d(0, ${(props) => (props.visible ? 0 : 20)}px, 0);
  opacity: ${(props) => (props.visible ? 1 : 0)};
  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    font-size: ${(props) => props.theme.mobile.fontSize.body};
  }
`

const CTAContainer = styled.div<VisibleProps>`
  transition: ${duration}ms ${easing};
  transition-delay: ${delay * 2}ms;
  transform: translate3d(0, ${(props) => (props.visible ? 0 : 20)}px, 0);
  opacity: ${(props) => (props.visible ? 1 : 0)};
`

const OtherLinksWrapper = styled.div<VisibleProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;

  transition: ${duration}ms ${easing};
  transition-delay: ${delay * 3}ms;
  transform: translate3d(0, ${(props) => (props.visible ? 0 : 20)}px, 0);
  opacity: ${(props) => (props.visible ? 1 : 0)};
`

const OtherLinksButton = styled.a`
  height: 2.2rem;
  width: 2.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: teal; */
  margin-bottom: 20px;
  opacity: 0.25;
  cursor: pointer;
  transition: 250ms;
  padding: 0px 1rem;
  /* color: teal; */

  &:hover {
    transform: translateY(-20%);
    opacity: 0.5;
  }
`

const OtherLinkImage = styled.img`
  width: 75%;
  height: auto;
  object-fit: contain;
  /* color: lightsalmon; */
`

const EmailLink = styled.h2`
  font-family: ${(props) => props.theme.fontFamily.regular};
  font-weight: 400;
  font-size: ${(props) => props.theme.fontSize.header};
  color: ${COLORS.primary};
  margin-top: 5px;
  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    font-size: ${(props) => props.theme.mobile.fontSize.header};
  }
`

interface PopupProps {
  visible: boolean
}

const PopupContainer = styled.div<PopupProps>`
  position: absolute;
  bottom: -60px;
  right: 0;
  pointer-events: none;
  /* right: ${(props) => props.theme.paddings.extendedContent}px; */
  background-color: rgb(22 22 23);
  padding: 1rem 1.4rem;
  border-radius: 5px;
  transition: 500ms cubic-bezier(0.68, -0.6, 0.32, 1.6);
  transform: translate3d(0, ${(props) => (props.visible ? '0' : '-100')}%, 0);
  opacity: ${(props) => (props.visible ? 1 : 0)};
`

const PopupText = styled.p`
  font-family: ${(props) => props.theme.fontFamily.regular};
  font-weight: 300;
  font-size: ${(props) => props.theme.fontSize.small};
  color: ${(props) => props.theme.colors.primary};
  text-align: center;
  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    font-size: ${(props) => props.theme.mobile.fontSize.small};
  }
`
