import tech_bg from '@images/tech_bg.svg'
import {
  SectionMainText,
  sectionMainTextAnimParams,
} from '@shared-components/sectionMainText'
import { MOBILE_BREAK_POINT } from '@styles/styles'
import React from 'react'
import { useInView } from 'react-intersection-observer'
import styled from 'styled-components'
import { techData } from './textStackData'
import { TechStackCarousel } from './_techStackCarousel'

interface IsProps {}

export const TechStack = React.forwardRef(
  ({}: IsProps, scrollRef: React.Ref<HTMLDivElement>) => {
    const { ref, inView } = useInView({ threshold: 1, triggerOnce: true })

    /**The amount of time in ms before firing the description animation. This should only be used in the desktop version since the carousel will fall onto a different screen on mobile*/
    // const inDelay = isMobile ? 0 : techStackCarouselDuration - 100

    return (
      <Wrapper ref={scrollRef}>
        <BodyContainer>
          <LeftContainer {...{ ref }}>
            <SectionMainText visible={inView} {...techData} />
            {/* 
          This is currently not in use. Create a tech stack page and put this back on.
          <CTAButtonContainer visible={inView} inDelay={inDelay}>
            <CTAButton
              //TODO what's happening here?
              href={'www.google.com'}
              target='_blank'
              rel='noopener noreferrer'
            >
              Full Skill Set
            </CTAButton>
          </CTAButtonContainer> */}
          </LeftContainer>
          <TechStackCarousel />
        </BodyContainer>
      </Wrapper>
    )
  }
)

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  /* flex-direction: column; */
  /* justify-content: center; */
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-image: url(${tech_bg});
  background-repeat: no-repeat;
  background-position: 3% 177%;
  background-size: 44%;
`

const BodyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: ${(props) => props.theme.widths.content};
  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    flex-direction: column-reverse;
    max-width: ${(props) => props.theme.widths.maxMobile};
  }

  /* background-color: teal; */
`

const LeftContainer = styled.div`
  max-width: 45%;
  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    max-width: 100%;
    text-align: center;
    margin: 5rem 0;
  }

  /* margin-right: auto; */
  /* margin-left: auto; */
`

interface VisibleProps {
  visible: boolean
  inDelay: number
}

const CTAButtonContainer = styled.div<VisibleProps>`
  transition: ${sectionMainTextAnimParams.duration}ms;
  transition-delay: ${(props) =>
    props.visible
      ? props.inDelay +
        sectionMainTextAnimParams.totalDuration -
        sectionMainTextAnimParams.delay
      : 0}ms;
  transform: translateY(${(props) => (props.visible ? 0 : 20)}px);
  opacity: ${(props) => (props.visible ? 1 : 0)};
`
