import { randomNumberGenerator } from '@helpers/randomNumberGenerator'
import { BACKGROUND_COLORS, COLORS, MOBILE_BREAK_POINT } from '@styles/styles'
import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'

const cities = [
  'Paris',
  'Los Angeles',
  'Melbourne',
  'Tokyo',
  'London',
  'New York',
  'Shanghai',
  'Munich',
  'Milan',
  'Montreal',
  'Sydney',
  'Rome',
  'Beijing',
  'Berlin',
  'Bucharest',
  'Frankfurt',
  'Vienna',
  'Doha',
  'Chicago',
  'Stockholm',
  'Saigon',
  'Dubai',
]

const fontSize = 30
const canvas_v_margin = 0.5

class AnimatedWord {
  x: number
  y: number
  city_i: number
  fontSize: number
  rowSize: number
  word: string
  canvasWidth: number
  canvasHeight: number
  speed: number
  launchedNext: boolean

  constructor(
    y: number,
    city_i: number,
    fontSize: number,
    rowSize: number,
    canvasWidth: number,
    canvasHeight: number
  ) {
    this.x =
      (canvasWidth <= MOBILE_BREAK_POINT ? 0 : canvasWidth * 0.35) +
      randomNumberGenerator(0, 10)
    this.y = y
    this.city_i = city_i
    this.fontSize = fontSize
    this.rowSize = rowSize
    this.word = cities[this.city_i % cities.length]
    this.canvasWidth = canvasWidth
    this.canvasHeight = canvasHeight
    this.speed = Math.random() * 3 + 2
    this.launchedNext = false
  }

  draw(ctx: CanvasRenderingContext2D) {
    ctx.fillText(
      this.word,
      this.x,
      this.canvasHeight * (canvas_v_margin / 2) +
        this.y * this.rowSize +
        this.rowSize / 2
    )

    // if (this.x > this.canvasWidth * 1.1) {
    //   this.x = this.canvasWidth * 0.35 + randomNumberGenerator(0, 10)
    // } else {
    //   this.x += this.speed
    // }
    this.x += this.speed
  }
}

class Effect {
  canvasWidth: number
  canvasHeight: number
  fontSize: number
  rowSize: number
  rows: number
  city_i: number
  symbols: AnimatedWord[]

  constructor(canvasWidth: number, canvasHeight: number) {
    this.canvasWidth = canvasWidth
    this.canvasHeight = canvasHeight
    this.fontSize = fontSize
    this.rowSize = this.fontSize * 3
    this.rows = Math.floor(
      (this.canvasHeight * (1 - canvas_v_margin)) / this.rowSize
    )
    this.city_i = 0
    this.symbols = []
    this.initialize()
  }

  initialize() {
    for (let i = 0; i < this.rows; i++) {
      this.symbols[i] = new AnimatedWord(
        i,
        this.city_i,
        this.fontSize,
        this.rowSize,
        this.canvasWidth,
        this.canvasHeight
      )

      this.city_i++
    }
  }

  update(ctx: CanvasRenderingContext2D) {
    for (let i = 0; i < this.symbols.length; i++) {
      let symbol = this.symbols[i]

      if (symbol) {
        symbol.draw(ctx)

        if (symbol.x > this.canvasWidth * 1.1) {
          this.symbols.splice(i, 1)
          i--
        } else if (
          symbol.x > this.canvasWidth * 0.65 &&
          !symbol.launchedNext &&
          Math.random() > 0.9
        ) {
          symbol.launchedNext = true

          this.symbols.push(
            new AnimatedWord(
              symbol.y,
              this.city_i,
              this.fontSize,
              this.rowSize,
              this.canvasWidth,
              this.canvasHeight
            )
          )
          this.city_i++
        }
      }
    }
  }

  /**Used when the window is resized */
  resize(width: number, height: number) {
    this.canvasWidth = width
    this.canvasHeight = height
    this.rows = Math.floor(
      (this.canvasHeight * (1 - canvas_v_margin)) / this.rowSize
    )
    this.symbols = []
    this.initialize()
  }
}

interface IsProps {
  inView: boolean
}

export const AboutAnimation = ({ inView }: IsProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  useEffect(() => {
    const canvas = canvasRef.current!
    const ctx = canvas?.getContext('2d')!
    /**Used for canceling the animation on unmount */
    let animationFrameId: number

    canvas.width = window.innerWidth
    canvas.height = window.innerHeight

    let gradient = ctx.createLinearGradient(0, 0, canvas.width, 0)
    // gradient.addColorStop(0, `${COLORS.primary}00 `)
    gradient.addColorStop(0.45, `${COLORS.primary}00 `)
    gradient.addColorStop(0.65, COLORS.primary)
    gradient.addColorStop(1, `${COLORS.primary}00 `)
    // gradient.addColorStop(0.9, COLORS.primary)
    // gradient.addColorStop(1, `${COLORS.primary}0D `)

    //This is getting reassigned when the animation is stopped. That's why it doesn't "pause" but instead restarts
    const effect = new Effect(canvas.width, canvas.height)

    const resizeEvent = () => {
      if (canvas) {
        canvas.width = window.innerWidth
        canvas.height = window.innerHeight

        //Reassign the same gradient variable so that it can be used in the fillStyle call in animate()
        gradient = ctx.createLinearGradient(0, 0, canvas.width, 0)
        gradient.addColorStop(0.45, `${COLORS.primary}00 `)
        gradient.addColorStop(0.65, COLORS.primary)
        gradient.addColorStop(1, `${COLORS.primary}00 `)

        // ctx.fillStyle = gradient

        effect.resize(canvas.width, canvas.height)
      }
    }

    let lastTime = 0
    const fps = 60
    const nextFrame = 1000 / fps
    let timer = 0

    const animate = (timeStamp: number) => {
      const deltaTime = timeStamp - lastTime
      lastTime = timeStamp
      if (timer > nextFrame) {
        ctx.textAlign = 'center'
        ctx.fillStyle = BACKGROUND_COLORS.screen
        ctx.fillRect(0, 0, canvas.width, canvas.height)
        ctx.fillStyle = gradient //Make sure this comes after the above fill style for the background
        ctx.font = '500 ' + effect.fontSize + 'px inter'
        // effect.symbols.forEach((symbol) => {
        //   symbol.draw(ctx)
        // })
        effect.update(ctx)
        timer = 0
      } else {
        timer += deltaTime
      }
      animationFrameId = window.requestAnimationFrame(animate)
    }
    if (inView) {
      animate(0)
    } else if (animationFrameId!) {
      window.cancelAnimationFrame(animationFrameId)
    }

    window.addEventListener('resize', resizeEvent)

    return () => {
      window.removeEventListener('resize', resizeEvent)
      window.cancelAnimationFrame(animationFrameId)
    }
  }, [inView])

  return (
    <Wrapper>
      <Canvas ref={canvasRef} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: -2;
`

const Canvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  /* z-index: -2; */
  /* background-color: teal; */
`
