import styled from 'styled-components'

/**Width is in px */
export const MOBILE_BREAK_POINT = 768

export const MOBILE_SECTION_PADDING = '4em'

export const COLORS = {
  primary: '#14E1BC',
  secondary: '#D811AC',
  text1: 'rgba(255,255,255,0.95)',
  text2: 'rgba(255,255,255,0.85)',
  text3: 'rgba(255,255,255,0.5)',
  backgroundImages: 'rgba(255,255,255,0.02)',
}

export const BACKGROUND_COLORS = {
  // screen: '#17161a',
  screen: '#0a0a0b',
}

export const WIDTHS = {
  maxMobile: `clamp(200px, 90vw, 100vw - 5rem)`,
  maxDesktop: `1240px`,
  content: `980px`,
}

export const PADDINGS = {
  extendedContent: 80,
}

export const theme = {
  fontFamily: {
    regular: 'inter',
  },
  fontSize: {
    jumbo: `clamp(2rem, 5vh, 4rem)`,
    // header: `clamp(1.2rem, 4vh, 3rem)`,
    header: '1.8rem',
    // subheader: `clamp(1rem, 3.2vh, 2.4rem)`,
    subheader: '1.4rem',
    body: '1.1rem',
    small: '0.8rem',
  },
  mobile: {
    fontSize: {
      jumbo: `7vw`,
      header: `1.8em`,
      subheader: `1.5em`,
      body: `1rem`,
      small: `0.8rem`,
    },
  },
  backgroundColors: BACKGROUND_COLORS,
  colors: COLORS,
  widths: WIDTHS,
  /**Paddings are in px */
  paddings: PADDINGS,
}

export type MyTheme = typeof theme

/**
 * A div that has its max-width set along with a breakpoint for mobile
 */
export const ContentWrapper = styled.div`
  max-width: ${(props) => props.theme.widths.maxDesktop};

  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    max-width: ${(props) => props.theme.widths.maxMobile}vw;
  }
`

export const SectionHeader = styled.h1`
  font-family: ${(props) => props.theme.fontFamily.regular};
  font-weight: 700;
  font-size: ${(props) => props.theme.fontSize.header};
  color: ${(props) => props.theme.colors.primary};
  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    font-size: ${(props) => props.theme.mobile.fontSize.header};
  }
`

export const SectionSubheader = styled.h2`
  font-family: ${(props) => props.theme.fontFamily.regular};
  font-weight: 500;
  font-size: ${(props) => props.theme.fontSize.subheader};
  color: ${(props) => props.theme.colors.text1};
  margin-top: 5px;
  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    font-size: ${(props) => props.theme.mobile.fontSize.subheader};
  }
`

export const SectionBodyText = styled.p`
  font-family: ${(props) => props.theme.fontFamily.regular};
  font-size: ${(props) => props.theme.fontSize.body};
  color: ${(props) => props.theme.colors.text2};
  font-weight: 400;
  white-space: pre-line;
  margin-top: 20px;
  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    font-size: ${(props) => props.theme.mobile.fontSize.body};
  }
`

export const SectionBodyTextLink = styled.a`
  color: ${(props) => props.theme.colors.primary};
  cursor: pointer;
  transition: 350ms;
  &:hover {
    color: ${(props) => props.theme.colors.secondary};
  }
`

interface CTAButtonProps {
  noMarginTop?: boolean
}

/**
 * The call to action button used in the home section
 */
export const CTAButton = styled.a<CTAButtonProps>`
  display: inline-block;
  padding: 0.6em 1.7em;
  border-radius: 0.2em;
  border: ${(props) => `1px solid ${props.theme.colors.primary}`};
  font-family: ${(props) => props.theme.fontFamily.regular};
  font-weight: 200;
  font-size: ${(props) => props.theme.fontSize.body};
  color: ${(props) => props.theme.colors.primary};
  margin-top: ${(props) => (props.noMarginTop ? 0 : 20)}px;
  cursor: pointer;
  transition: 250ms;
  text-decoration: none;
  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    font-size: ${(props) => props.theme.mobile.fontSize.body};
    font-weight: 500;
    padding: 0.5em 1.2em;
  }
  &:hover {
    background-color: ${(props) => `${props.theme.colors.primary}1A`};
    /* color: ${(props) => props.theme.colors.text1}; */
  }
`
