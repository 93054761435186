// import contactIcon from '@images/contact_alt.svg'
import arrowDown from '@images/arrow_down.svg'
import { BACKGROUND_COLORS, COLORS, MOBILE_BREAK_POINT } from '@styles/styles'
import React from 'react'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'

type ScrollTo = 'about' | 'tech' | 'contact' | 'work'

const links: {
  title: string
  scrollTo: ScrollTo
  special?: boolean
  hoverImage?: string
}[] = [
  { title: 'ABOUT', scrollTo: 'about' },
  { title: 'TECH', scrollTo: 'tech' },
  { title: 'WORK', scrollTo: 'work' },
  {
    title: 'CONTACT',
    scrollTo: 'contact',
    special: true,
    hoverImage: arrowDown,
  },
]

interface IsProps {
  visible: boolean
  scrollToSection: (sections: ScrollTo) => void
  showBackground: boolean
}

export const Navbar = ({
  visible,
  showBackground,
  scrollToSection,
}: IsProps) => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${MOBILE_BREAK_POINT}px)`,
  })

  if (isMobile) return null

  return (
    <Wrapper {...{ visible, showBackground }}>
      <Container>
        {links.map((link) =>
          link.hoverImage ? (
            // <AnimatedCTAButton
            //   buttonText={link.title}
            //   hoverImage={link.hoverImage}
            //   altColor
            //   smaller
            //   onPress={() => scrollToSection(link.scrollTo)}
            // />
            <NavLink
              key={link.title}
              special
              onClick={() => scrollToSection(link.scrollTo)}
            >
              {link.title}
            </NavLink>
          ) : (
            <NavLink
              key={link.title}
              onClick={() => scrollToSection(link.scrollTo)}
            >
              {link.title}
            </NavLink>
          )
        )}
      </Container>
    </Wrapper>
  )
}

interface WrapperProps {
  visible: boolean
  showBackground: boolean
}

const Wrapper = styled.div<WrapperProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 450ms;
  background-color: ${(props) =>
    props.showBackground ? `${BACKGROUND_COLORS.screen}BF` : 'transparent'};
  backdrop-filter: ${(props) =>
    props.showBackground ? 'saturate(180%) blur(20px)' : 'none'};
  z-index: 10;
  transform: translate3d(0, ${(props) => (props.visible ? '0%' : '-100%')}, 0);
  /* padding: 0 ${(props) => props.theme.paddings.extendedContent}px; */
`

const Container = styled.div`
  display: flex;
  flex: 1;
  /* max-width: ${(props) => props.theme.widths.maxDesktop}; */
  padding: 0 ${(props) => props.theme.paddings.extendedContent}px;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`

interface NavLinkProps {
  special?: boolean
}

const NavLink = styled.a<NavLinkProps>`
  position: relative;
  padding: 10px 20px;
  cursor: pointer;
  /* background-color: teal; */
  font-family: ${(props) => props.theme.fontFamily.regular};
  font-weight: 600;
  font-size: ${(props) => props.theme.fontSize.small};
  color: ${(props) => props.theme.colors.text2};
  text-transform: uppercase;
  border-width: 1px;
  border-color: ${(props) =>
    props.special ? props.theme.colors.secondary : 'transparent'};
  border-style: solid;
  margin-right: 10px;
  border-radius: 4px;
  transition: 250ms;

  &:after {
    display: ${(props) => (props.special ? 'none' : 'inline-block')};
    content: '';
    position: absolute;
    bottom: 0;
    left: 10px;
    width: calc(100% - 20px);
    /* margin: 0 10%; */
    height: 2px;
    background-color: ${COLORS.primary};
    transition: 250ms;
    transform-origin: left;
    transform: scaleX(0);
  }

  &:hover {
    background-color: ${(props) =>
      props.special ? `${COLORS.secondary}1A` : 'transparent'};
    color: ${(props) => (props.special ? COLORS.secondary : COLORS.primary)};
    /* border-color: ${COLORS.text1}; */
    &:after {
      transform: scaleX(1);
    }
  }
`
