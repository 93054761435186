import android_icon from '@images/android_white.svg'
import ios_icon from '@images/apple.svg'
import web_icon from '@images/chrome_white.svg'
import react_icon from '@images/techStack/React.svg'
import {
  animated,
  config,
  useChain,
  useSpring,
  useSpringRef,
  useTrail,
} from '@react-spring/web'
import { MOBILE_BREAK_POINT } from '@styles/styles'
import React from 'react'
import styled from 'styled-components'

const icons = [
  { icon: android_icon, name: 'Android' },
  { icon: web_icon, name: 'Web' },
  { icon: ios_icon, name: 'IOS' },
]

interface IsProps {
  unmountMe: () => void
}

export const LoadingScreen = ({ unmountMe }: IsProps) => {
  const iconsEnterAnim = useSpringRef()
  const trails = useTrail(icons.length, {
    ref: iconsEnterAnim,
    config: config.slow,
    // loop: true,
    from: { opacity: 0, y: 10 },
    to: [{ opacity: 1, y: 0 }, { opacity: 0 }],
    delay: 1000,
    onRest: () => {
      //Doing it this way because useChain has a bug that doesn't wait for useTrail to finish
      // tileExitApi.resume()
      reactLogoInApi.resume()
    },
  })

  const reactLogoAnim = useSpringRef()
  const [reactLogoIn, reactLogoInApi] = useSpring(() => ({
    ref: reactLogoAnim,
    config: { mass: 1, tension: 220, friction: 40 },
    from: { opacity: 0, rotate: 0 },
    to: [{ opacity: 1, rotate: 270 }],
    pause: true, //See above onRest call
    delay: 750,
    // onRest: () => {
    //   developerTextInAPI.resume()
    // },
  }))

  const developerTextAnim = useSpringRef()
  const [developerTextIn, developerTextInAPI] = useSpring(() => ({
    ref: developerTextAnim,
    config: { mass: 1, tension: 280, friction: 80 },
    from: { width: '0%' },
    to: [{ width: '100%' }],
    // pause: true,
  }))

  const tilesExitAnim = useSpringRef()
  const [tileExit, tileExitApi] = useSpring(() => ({
    ref: tilesExitAnim,
    config: { mass: 1, tension: 160, friction: 30 },
    from: { y: '0%', height: '100%', opacity: 1 },
    to: { y: '-60%', height: '0%', opacity: 0 },
    // loop: true,
    // delay: 750,
    // pause: true,
    onRest: () => {
      unmountMe()
    },
  }))

  useChain([reactLogoAnim, developerTextAnim, tilesExitAnim])

  return (
    <Wrapper style={tileExit}>
      <Container>
        <CenterContainer>
          {icons.map((icon, i) => (
            <IconContainer key={i}>
              <IconImage style={trails[i]} src={icon.icon} />
            </IconContainer>
          ))}
        </CenterContainer>
      </Container>
      <Container>
        <CenterContainer>
          <DeveloperContainer>
            {/* <IconContainer> */}
            <DeveloperIconImage style={reactLogoIn} src={react_icon} />
            {/* </IconContainer> */}
            {/* <DeveloperTextContainer> */}
            <DeveloperTextSubContainer style={developerTextIn}>
              <DeveloperText>{`React\nDeveloper`}</DeveloperText>
            </DeveloperTextSubContainer>
            {/* </DeveloperTextContainer> */}
          </DeveloperContainer>
        </CenterContainer>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled(animated.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 1);
  /* background-color: teal; */
  overflow: hidden;
  transform-origin: top;
`

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

const CenterContainer = styled.div`
  /* width: 50vw; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  /* background-color: teal; */
`

const IconContainer = styled(animated.div)`
  height: 100%;
  width: 15vw;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    width: calc(100vw / ${icons.length});
  }
  /* border: 1px solid rgba(255, 255, 255, 0.1); */
`

const IconImage = styled(animated.img)`
  width: 30%;
  height: auto;
`

const DeveloperContainer = styled(CenterContainer)`
  /* width: 100%; */
  /* overflow: hidden; */
`

const DeveloperIconImage = styled(animated.img)`
  width: 5vw;
  height: auto;
  margin: 0 20px;
  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    width: 15vw;
  }
`

const DeveloperTextSubContainer = styled(animated.div)`
  /* font-family: ${(props) => props.theme.fontFamily.regular}; */
  /* font-size: ${(props) => props.theme.fontSize.jumbo}; */
  /* color: ${(props) => props.theme.colors.text1}; */
  /* min-width: 100%; */
  overflow: hidden;
`

const DeveloperText = styled.h2`
  font-family: ${(props) => props.theme.fontFamily.regular};
  font-size: ${(props) => props.theme.fontSize.jumbo};
  color: ${(props) => props.theme.colors.text1};
  white-space: pre;
  font-weight: 500;
  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    font-size: ${(props) => props.theme.mobile.fontSize.jumbo};
  }
`
